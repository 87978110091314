import { FooterStyles } from "../../assents/globalStyles/footerStyles";
import React from "react";
import { ProjectHost } from "../../assents/keys/projectHost";

function Footer(){
    return(
        <FooterStyles>
            <div id={'Copirights footer '} >
                <h4>© Copyright 2021 Alexandre Picinatto  - All Rights Reserved</h4>
                <h4>{ProjectHost} is a Alexandre Picinatto Project </h4>
                <h4> Maded by Alexandrepicinato.com</h4>
                <h4> Developed in <a href={"/wellcome"}>Laravel</a></h4>
                <h4> Hosted by Unifique Telecom (FrontEnd)/ LocaWeb(BackEnd )  </h4>
            </div>
        </FooterStyles>
    )
}
export{
    Footer
}