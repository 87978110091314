import logo from './logo.svg';
import './App.css';
import Menu from './components/menu/menu';
import Main from './main';
import { Footer } from './components/footer/footer';
import LoguinUi from './components/login/login';

function App() {
  return (
    <>
      <Main/>
      <Footer />
    </>
  );
}

export default App;
